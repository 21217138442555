import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.css";
import hand from './../../../assets/images/Hand.gif';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function Home() {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesOptions = {
    interactivity: {
      detect_on: "window",
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
        },
        onclick: {
          enable: true,
          mode: "push",
        },
      },
      modes: {
        repulse: {
          distance: 70,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
      },
    },
    particles: {
      number: {
        value: 50,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: ["#2EB67D", "#ECB22E", "#E01E5B", "#36C5F0"],
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#fff",
        },
        polygon: {
          nb_sides: 5,
        },
        image: {
          src: "https://cdn.freebiesupply.com/logos/large/2x/slack-logo-icon.png",
          width: 100,
          height: 100,
        },
      },
  
      opacity: {
        value: 1,
        random: false,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 8,
        random: true,
        anim: {
          enable: false,
          speed: 10,
          size_min: 10,
          sync: false,
        },
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#808080",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    detectRetina: true,
    style: {
      position: "relative",
    }
  }

  return (
    <div>
      <div id="home">
        <section className="center">
          <Particles
            id="particles-js"
            init={particlesInit}
            options={particlesOptions}
          />
        </section>
      </div>
      <div className='text-center'>
        <div className="text col-sm-6" style={{marginTop: '140px'}}>
          <div className="center">
            <div>
              <img src={'https://avatars.githubusercontent.com/u/10825866?v=4'} alt="Erdem Demirkapı" className="rounded-circle img-fluid" width="220"/>
            </div>
            <div className='mt-4'>
              Hi <span className="wave"><img src={hand} alt='hand' width="35px"/></span>&nbsp;, I'm <span className="header">Erdem Demirkapı.</span> I'm a software engineer who is specializing in back-end technologies and working as a freelancer.
            </div>
            <div>
              <div className="social-icons mt-4">
                <a className="socialicon linkedin" href="https://linkedin.com/in/erdemdemirkapi/" target="_blank" rel="noreferrer">
                  <svg className="linkedin-icon feather feather-linkedin" xmlns="http://www.w3.org/2000/svg" width="35" viewBox="0 0 24 24" fill="#0e76a8" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                </a>
                <a className="socialicon github" href="https://github.com/erdemdemirkapi/" target="_blank" rel="noreferrer">
                  <svg className="github-icon" width="45px" height="45px" viewBox="0 0 300 300">
                    <path id="body" d="M112.5,234.4v43.1c24.5,7.5,50.5,7.5,75,0V225c-0.4-11.1-4.9-21.6-12.7-29.6c30-3.4,59.5-23.5,59.5-64.1
                                                c0.9-13.9-3.3-27.7-11.8-38.7c4.1-11.6,3.7-24.4-1.3-35.7c0,0-11.2-3.7-37.5,13.8c-22.1-6.1-45.3-6.1-67.4,0
                                                C90.6,53.3,78.9,56.8,78.9,56.8c-5,11.3-5.4,24.1-1.3,35.7c-8.5,11.1-12.7,24.8-11.9,38.7c0.2,33.8,26.4,61.8,60.2,64.1
                                                c-5,4.7-8.1,11.1-8.6,18" />
                    <path id="arm" d="M125.2,213.4c-13.9,7.8-31.5,2.8-39.3-11.2c-4.3-7.7-12-12.8-20.7-13.8c-11.2,0-4.6,6.4,0,9.4
                                                    c6,5.1,10.8,11.5,13.8,18.8c2.9,7.4,7.7,24.1,41.5,17.8" />
                  </svg>
                </a>
                <a className="socialicon stackoverflow" href="https://stackoverflow.com/users/7784236/erdem" target="_blank" rel="noreferrer">
                  <svg className='stackoverflow-icon' width="45px" height="45px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M290.7 311L95 269.7 86.8 309l195.7 41zm51-87L188.2 95.7l-25.5 30.8 153.5 128.3zm-31.2 39.7L129.2 179l-16.7 36.5L293.7 300zM262 32l-32 24 119.3 160.3 32-24zm20.5 328h-200v39.7h200zm39.7 80H42.7V320h-40v160h359.5V320h-40z"/>
                  </svg>
                </a>
                <a className="socialicon google" href="mailto:demirkapierdem@gmail.com" target="_blank" rel="noreferrer">
                  <svg className='google-icon' width="45px" height="45px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
