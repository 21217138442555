import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Timeline from "./../../elements/timeline/Timeline";

function Experience() {
  return (
    <section className="section" id="experience" style={{paddingTop: '5rem'}}>
      <div className="container">
        <h1 className="title">Experience</h1>
        <Timeline />
      </div>
    </section>
  );
}

export default Experience;
