import React from "react";
import "./../../../assets/css/style.css";

function TimelineHeader(props) {
  return (
    <header className="timeline-header">
        <span className="badge bg-success fs-5">{props.year}</span>
    </header>
  );
}

export default TimelineHeader;
