import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./../components/structure/header/Header";
import Content from "./../components/structure/content/Content";
import Footer from "./../components/structure/footer/Footer";

const IndexPage = () => {

  return (
    <main>
      <Header />
      <Content />
      <Footer />
    </main>
  )
}

export default IndexPage
