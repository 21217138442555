import React from "react";
import Home from "./../../sections/home/Home";
import Experience from "./../../sections/experience/Experience";

function Content() {
  return (
    <main>
      <Home />
      <Experience />
    </main>
  );
}

export default Content;
