import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Header.css";

function Header() {
  const [lightMode, setLightMode] = useState(typeof window !== 'undefined' ? localStorage.lightMode : '');

  useEffect(() => {
    var app = document.getElementsByTagName("HTML")[0];
    var nav = document.getElementById("navbar");
    
    if (localStorage.lightMode === "dark") {
      app.setAttribute("light-mode", "dark");
      nav.classList.add("dark-theme");
      setLightMode('dark');
    } else {
      app.setAttribute("light-mode", "light");
      nav.classList.remove("dark-theme");
      setLightMode('light');
    }
    
    updateColorOfSwiperBullets(localStorage.lightMode);
  }, [lightMode]);

  const toggleLightMode = () => {
    var app = document.getElementsByTagName("HTML")[0];
    var nav = document.getElementById("navbar");
    var sc = document.getElementsByClassName("socialicon");
    if (localStorage.lightMode === "dark") {
      localStorage.lightMode = "light";
      app.setAttribute("light-mode", "light");
      nav.classList.remove("dark-theme");
      setLightMode('light');
      for (var i = 0; i < sc.length; i++) {
        sc[i].classList.remove("dsc");
      }
    } else {
      nav.classList.add("dark-theme");
      localStorage.lightMode = "dark";
      app.setAttribute("light-mode", "dark");
      setLightMode('dark');
      for (i = 0; i < sc.length; i++) {
        sc[i].classList.add("dsc");
      }
    }

    updateColorOfSwiperBullets(localStorage.lightMode);
  };

  const updateColorOfSwiperBullets = (lightMode) => {
    document.querySelectorAll(".swiper-pagination-bullet").forEach((bullet) => {
      if (lightMode === "light") {
        bullet.style.backgroundColor = "blue";
      } else {
        bullet.style.backgroundColor = "white";
      }
    });
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top dark-theme" id="navbar">
      <div className="container-fluid">
        <a className="navbar-brand" href="#home">Erdem Demirkapı</a>
        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto" id="navbar-content">
            <li className="nav-item nav-item-hover"><a className="nav-link" href="#experience">Experience</a></li>
            <li className="nav-item nav-item-hover"><a className="nav-link" href="#contact-me">Contact Me</a></li>
            <li className="nav-item">
            <input type="checkbox" id="dark_toggler" className="dark_toggler" aria-label="Toggle Light Mode" onClick={event => toggleLightMode()} defaultChecked={lightMode === 'dark' ? true : false} />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Header;
